import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="center-content">
        <h1>Our website is under construction...</h1>
        <img src="logo.png" alt="Under Construction" className="center-image" />
        <h2>Matryoshka Solutions</h2>
      </div>
    </div>
  );
}

export default App;
